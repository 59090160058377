import { nanoid } from 'nanoid';

// HEAD DATA
export const headData = {
  title: 'Damilola Alao | Software Engineer', // e.g: 'Name | Developer'
  lang: 'en', // e.g: en, es, fr, jp
  description: 'Welcome to my website', // e.g: Welcome to my website
};

// HERO DATA
export const heroData = {
  title: 'Hi, my name is',
  name: 'Damilola Alao',
  subtitle: "I'm a Software Engineer",
  cta: 'See more',
};

// ABOUT DATA
export const aboutData = {
  img: 'profile.jpg',
  paragraphOne:
    "I'm a  FullStack Software Engineer who has worked on a couple of projects and worked with different people in the course of my five-year programming journey.",
  paragraphTwo:
    'I love tackling new challenges and ready to always learn new things and technologies.',
  paragraphThree: null,
  resume: 'https://drive.google.com/file/d/1l3MYEXTkex9V3YxvHrtn_rzk8yI77I9l/view?usp=sharing', // if no resume, the button will not show up
};

// PROJECTS DATA
export const projectsData = [
  {
    id: nanoid(),
    img: 'vendorbase-ng.jpg',
    title: 'VendorBase',
    info: 'VendorBase is an ecommerce marketplace for buyer and sellers to meet and do business',
    info2: '',
    url: 'https://vendorbase.ng',
    repo: null, // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'estore-crypto.jpg',
    title: 'Crypto Store',
    info: 'An Ecommerce Store were products can be bought and paid for in cryptocurrency',
    info2: '',
    url: 'https://strapi-starter-nuxt-e-commerce-three.vercel.app',
    repo: null, // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'top-crypto.jpg',
    title: 'Top CryptoCurrency',
    info: 'A list of top cryptocurrencies their total worth, price and recent changes in value',
    info2: '',
    url: 'https://coinlist-blush.vercel.app',
    repo: null, // if no repo, the button will not show up
  },
];

// CONTACT DATA
export const contactData = {
  cta: '',
  btn: '',
  email: 'oluwadamilola.alao@gmail.com',
};

// FOOTER DATA
export const footerData = {
  networks: [
    {
      id: nanoid(),
      name: 'twitter',
      url: 'https://twitter.com/DamilolaAlao_',
    },
    {
      id: nanoid(),
      name: 'codepen',
      url: 'https://codepen.io/DamilolaAlao',
    },
    {
      id: nanoid(),
      name: 'linkedin',
      url: 'https://www.linkedin.com/in/oluwadamilolaalao',
    },
    {
      id: nanoid(),
      name: 'github',
      url: 'https://github.com/DamilolaAlao',
    },
  ],
};

// Github start/fork buttons
export const githubButtons = {
  isEnabled: false, // set to false to disable the GitHub stars/fork buttons
};
